import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import "bootstrap/dist/css/bootstrap.min.css"
import reportWebVitals from "./reportWebVitals"
import { BuProvider } from "./Helpers/Context/Context"
import ThemeProvider from "./Helpers/Theme/Theme"

ReactDOM.render(
  <BuProvider>
    <ThemeProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </ThemeProvider>
  </BuProvider>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
