import React, { useState, useEffect, useContext } from "react"
import { Button, Col, Form, Row, Table } from "react-bootstrap"
import { useNavigate, useParams, useLocation } from "react-router-dom"
import { Select, DatePicker } from "antd"
import toast from "react-hot-toast"
import {
  formatAmount,
  getTodayDateISO,
  getTodayDateISOFormat,
  toastStyle,
} from "../../Helpers/Utils/Common"
import trash from "./../../Assets/Images/trash.png"
import Navbar from "../../Components/Navbar/Navbar"
import { Fragment } from "react"
import ReactLoading from "react-loading"
import {
  updateProjectInvoice,
  recordStatusProjectInvoice,
} from "../../Helpers/apiCalls/ProjectInvoiceApi"
import dayjs from "dayjs"
import {
  getAllProjects,
  searchProject,
} from "../../Helpers/apiCalls/projectApi"
import {
  createBillingSalesInvoice,
  getSingleBillingSI,
  updateBillingSalesInvoice,
} from "../../Helpers/apiCalls/BillingApi"
import { UseGetRequest } from "../../Helpers/hooks/GetRequests"
import { Context } from "../../Helpers/Context/Context"

function FormBillingSalesInvoice({ add, edit, process, createinvoice }) {
  let navigate = useNavigate()
  const { selectedBuId } = useContext(Context)
  const [inactive, setInactive] = useState(true)
  const [isChanged, setIsChanged] = useState(false)
  const [isClicked, setIsClicked] = useState(false)
  const { id } = useParams()
  const location = useLocation()
  const [accountTypeOptions, setAccountTypeOptions] = useState([])
  const [formValues, setFormValues] = useState({
    vat_type: "vat_ex",
    invoice_no: "",
    remark: "",
    amount_due: 0,
    vat_in: 0,
  })

  const whtOptions = [
    { value: "W000_0", label: "0" },
    { value: "WC010_10", label: "WC010 EWT Professionals" },
    { value: "WC100_5", label: "WC100 EWT Rentals" },
    { value: "WC120_2", label: "WC120 EWT Services" },
    { value: "WC158_1", label: "WC158 EWT Goods" },
    { value: "WC160_2", label: "WC160 EWT Services" },
    { value: "WI010_10", label: "WI010 EWT Professionals" },
    { value: "WI100_5", label: "WI100 EWT Rentals" },
    { value: "WI158_1", label: "WI158 EWT Goods" },
    { value: "WI160_2", label: "WI160 EWT Services" },
  ]

  // DATA HANDLERS
  const [projectOptions, setProjectOptions] = useState([])
  const [particulars, setParticulars] = useState([
    {
      item_name: "",
      amount: 0,
    },
  ])

  function handleAddDetailsChange(e, search) {
    const newList = { ...formValues }
    const { name, value } = e.target
    newList[name] = value
    if (name === "service_fee" || name === "delivery_fee") {
      setIsChanged(!isChanged)
    }
    setFormValues(newList)
  }

  /**
   *  ORDER ITEMS
   */
  // const [orderItems, setOrderItems] = useState([]);

  async function handleSubmitInvoice() {
    if (isClicked) {
      return
    }
    // if (
    //   !isClicked &&
    //   validateProjectInvoice(formValues, particulars, setIsError)
    // ) {
    setIsClicked(true)
    const response = await createBillingSalesInvoice(
      formValues,
      particulars,
      []
      // fileList
    )
    if (response.data) {
      if (response.data.status === "error") {
        toast.error(response.data.response, {
          style: toastStyle(),
        })
        // setTimeout(() => refreshPage(), 1000);
      } else if (response.data.status === "success") {
        toast.success("Successfully created billing sales invoice", {
          style: toastStyle(),
        })
        navigate("/billingsalesinvoice/print/" + response.data.id, "_blank")
        // setTimeout(
        //   () =>
        //     navigate(
        //       "/billingsalesinvoice/print/" + response.data.id,
        //       "_blank"
        //     ),
        //   1000
        // );
      }
    } else {
      var errMsg = response.error.response
      toast.error(errMsg, { style: toastStyle() })
      setIsClicked(false)
    }
    setIsClicked(false)
    // } else {
    //   toast.error("Please fill in all fields", { style: toastStyle() })
    // }
  }

  async function handleUpdatePI() {
    setIsClicked(true)
    const response = await updateBillingSalesInvoice(
      id,
      formValues,
      particulars
    )
    if (response.data?.status === "success") {
      toast.success("Successfully updated sales invoice", {
        style: toastStyle(),
      })
      navigate("/billingsalesinvoice/print/" + id)
      // setTimeout(() => navigate("/billingsalesinvoice/print/" + id), 1000);
    } else if (response.error) {
      toast.error("Error updating sales invoice", {
        style: toastStyle(),
      })
      setIsClicked(false)
    }
    setIsClicked(false)
  }

  async function handleCreateInvoiceBtn() {
    setIsClicked(true)
    const response = await updateProjectInvoice(formValues, particulars)
    if (response.data) {
      const record = await recordStatusProjectInvoice(id, "invoiced")
      if (record.data) {
        toast.success("Successfully created invoice", {
          style: toastStyle(),
        })
        navigate("/billingsalesinvoice/print/" + id)
        // setTimeout(() => navigate("/billingsalesinvoice/print/" + id), 1000);
      }
    } else if (response.error) {
      toast.error("Error updating billing sales invoice", {
        style: toastStyle(),
      })
      setIsClicked(false)
    }
    setIsClicked(false)
  }

  const handleSubmit = () => {
    if (add) handleSubmitInvoice()
    else if (edit) handleUpdatePI()
    else if (createinvoice) handleCreateInvoiceBtn()
  }

  /** FOR EDIT - Fetch Sales Invoice Details */
  async function fetchDetails() {
    const response = await getSingleBillingSI(id)
    if (response.data) {
      const res = response.data.data[0]
      if (process || createinvoice) {
        res.payment_type = "cash"
        res.paid_amount = "0"
        res.payment_date = getTodayDateISO()
        res.deposit_date = getTodayDateISO()
        res.due_date = getTodayDateISOFormat(res.due_date)
      }
      const tempParticulars = res.billing_sales_invoice_items?.map((row) => {
        return {
          ...row,
          account_title: row.type,
        }
      })

      setFormValues({
        ...res,
        invoice_date: dayjs(res.invoice_date, "YYYY-MM-DD"),
        due_date: dayjs(res.due_date, "YYYY-MM-DD"),
        subtotal: res.sub_total,
        vat_twelve: res.twelve_percent_vat,
        vat_net: res.net_vat,
        wht_percent_value: res.wht_percentage,
        wht_amount: res.wht_amount,
        grand_total: res.grand_total,
      })
      setParticulars(tempParticulars)
    }
  }

  console.log()

  function handleAddNewRow() {
    const newItem = {
      item_name: "",
      amount: 0,
    }
    setParticulars((oldItems) => [...oldItems, newItem])
  }

  function handleDelRow(id) {
    setIsChanged(true)

    const newList = [...particulars]
    newList.splice(id, 1)
    setParticulars(newList)

    const tempSubtotal = newList.reduce((tempSum, item) => {
      return tempSum + parseFloat(item.amount)
    }, 0)
    setFormValues((prev) => ({ ...prev, subtotal: tempSubtotal }))
    setTimeout(() => setIsChanged(false), 1)
  }

  function handleParticularChange(field, value, index) {
    const temp = [...particulars]
    temp[index][field] = value

    const tempSubtotal = temp.reduce((tempSum, item) => {
      return tempSum + parseFloat(item.amount)
    }, 0)
    setParticulars(temp)
    setFormValues((prev) => ({ ...prev, subtotal: tempSubtotal }))
  }

  async function fetchProjectParticulars(value) {
    const response = await searchProject({ project_id: value })
    if (response.data) {
      const res = response.data.data[0]
      setFormValues((prev) => ({
        ...prev,
        project_date: res.project_date,
        address: res.address,
        project_name: res.name,
        company: res.company,
      }))
    }
  }

  async function fetchAccountTypes() {
    const accountTypes = await UseGetRequest(
      "expense_types/get_all_expense_type"
    )
    if (accountTypes.data) {
      const res = accountTypes.data.data[0].expense_type_subtypes.map((row) => {
        return {
          value: row.id,
          label: row.name,
        }
      })
      setAccountTypeOptions(res)
    } else {
      setAccountTypeOptions([])
    }
  }

  async function fetchAllProjects() {
    setProjectOptions([])
    const response = await getAllProjects({ distributor_id: selectedBuId })
    if (response.data) {
      const result = response.data.data.map((item) => {
        return {
          name: "project_id",
          value: item.id,
          label: item.name,
        }
      })
      setProjectOptions(result)
    }
  }

  // DATA FETCHING
  useEffect(() => {
    if (edit || process || createinvoice) {
      fetchDetails()
    }
    fetchAllProjects()
    fetchAccountTypes()
  }, [])

  React.useEffect(() => {
    const totalSummary = { ...formValues }
    const tempGross =
      parseFloat(totalSummary.subtotal ?? 0) -
      parseFloat(totalSummary.discount ?? 0)

    const twelve = parseFloat(formValues.subtotal) * 0.12
    const tempVatIn = parseFloat(tempGross) + twelve
    const tempWhtAmount =
      parseFloat(tempGross) * (parseFloat(totalSummary.wht_percent ?? 0) / 100)
    const tempNet = tempVatIn - twelve
    // const tempGrandTotal =
    //   parseFloat(tempGross) -
    //   parseFloat(tempWhtAmount) +
    //   parseFloat(totalSummary.other_fees ?? 0);
    const tempAmountDue = tempNet - tempWhtAmount

    setFormValues((prev) => ({
      ...prev,
      wht_amount: tempWhtAmount,
      vat_twelve: twelve,
      vat_net: tempNet,
      vat_in: tempVatIn,
      amount_due: tempAmountDue,
      grand_total:
        formValues.vat_type === "vat_in" ? tempAmountDue + twelve : tempGross,
    }))
  }, [
    formValues.subtotal,
    formValues.wht_percent,
    formValues.discount,
    formValues.other_fees,
    formValues.vat_type,
  ])

  const [submittableForm, setSubmittableForm] = useState(false)
  React.useEffect(() => {
    const requiredFieldKeys = [
      "project_id",
      "invoice_date",
      "due_date",
      "invoice_no",
    ]

    let isValid = true

    if (particulars.length > 0) {
      const itemInvalid = particulars.some(
        (item) =>
          !item.description ||
          item.description.trim() === "" ||
          !item.amount ||
          item.amount.trim() === ""
      )
      isValid = !itemInvalid
    } else {
      isValid = false
    }

    requiredFieldKeys.forEach((key) => {
      if (
        !formValues[key] ||
        (typeof formValues[key] === "string" && formValues[key].trim() === "")
      ) {
        isValid = false
      }
    })
    setSubmittableForm(isValid)
  }, [formValues, particulars])

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive)
          }}
          active={"BILLING"}
        />
      </div>

      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        {/* header */}
        <div className="d-flex justify-content-between align-items-center">
          <h1 className="page-title mb-0">
            {add ? "ADD BILLING SALES INVOICE" : "EDIT BILLING SALES INVOICE"}
          </h1>
        </div>

        {/* content */}
        <div className="edit-form mt-2">
          {/* FRANCHISEE SALES INVOICE DETAILS */}
          <Fragment>
            <Row className="mt-4 mb-2">
              <Col>
                <span className="edit-label">
                  Project
                  <span className="color-red"> *</span>
                </span>
                {/* <Select
                  className="w-100"
                  value={formValues.project_id}
                  options={projectOptions}
                  onChange={(e) => {
                    setFormValues((prev) => ({ ...prev, project_id: e }))
                    fetchProjectParticulars(e)
                  }}
                /> */}
                <Select
                  showSearch
                  className="w-100"
                  placeholder="Select a project"
                  optionFilterProp="label"
                  value={projectOptions.find(
                    (item) => item.value === formValues.project_id
                  )}
                  onChange={(e) => {
                    setFormValues((prev) => ({ ...prev, project_id: e }))
                    fetchProjectParticulars(e)
                  }}
                  // onSearch={onSearch}
                  options={projectOptions}
                  // options={[
                  //   {
                  //     value: "jack",
                  //     label: "Jack",
                  //   },
                  //   {
                  //     value: "lucy",
                  //     label: "Lucy",
                  //   },
                  //   {
                  //     value: "tom",
                  //     label: "Tom",
                  //   },
                  // ]}
                />
              </Col>
              <Col xs={3}>
                <span className="edit-label">Contract Date</span>
                <Form.Control
                  disabled
                  type="date"
                  name="project_date"
                  className="nc-modal-custom-text"
                  value={
                    location.state
                      ? getTodayDateISOFormat(location.state.row.project_date)
                      : formValues.project_date
                  }
                />
              </Col>
              <Col xs={3}>
                <span className="edit-label">
                  Invoice Date
                  <span className="color-red"> *</span>
                </span>
                <DatePicker
                  className="nc-modal-custom-text w-100"
                  value={formValues.invoice_date}
                  onChange={(e) => {
                    if (e) {
                      setFormValues((prev) => ({
                        ...prev,
                        invoice_date: e,
                      }))
                    }
                  }}
                />
              </Col>
            </Row>

            <Row className="mt-4 mb-2">
              <Col>
                <span className="edit-label">Address</span>
                <Form.Control
                  disabled
                  type="text"
                  name="address"
                  defaultValue={
                    location.state
                      ? location.state.row.address
                      : formValues.address
                  }
                  className="nc-modal-custom-text"
                />
              </Col>
              <Col>
                <span className="edit-label">Company</span>
                <Form.Control
                  disabled
                  type="text"
                  name="company"
                  defaultValue={
                    location.state
                      ? location.state.row.company
                      : formValues.company
                  }
                  className="nc-modal-custom-text"
                />
              </Col>
              <Col xs={3}>
                <span className="edit-label">
                  Due Date
                  <span className="color-red"> *</span>
                </span>
                <DatePicker
                  className="nc-modal-custom-text w-100"
                  value={formValues.due_date}
                  onChange={(e) => {
                    if (e) {
                      setFormValues((prev) => ({
                        ...prev,
                        due_date: e,
                      }))
                    }
                  }}
                />
              </Col>
            </Row>

            <Row className="mt-4 mb-2">
              <Col lg={3}>
                <span className="edit-label">PO NO.</span>
                <Form.Control
                  type="text"
                  name="po_no"
                  value={formValues.po_no}
                  className="nc-modal-custom-text"
                  onChange={(e) => handleAddDetailsChange(e)}
                />
              </Col>
              <Col lg={3}>
                <span className="edit-label">
                  INVOICE NO.<span className="color-red"> *</span>
                </span>
                <Form.Control
                  type="text"
                  name="invoice_no"
                  value={formValues.invoice_no}
                  className="nc-modal-custom-text"
                  onChange={(e) => handleAddDetailsChange(e)}
                />
              </Col>
              <Col>
                <span className="edit-label">Remarks</span>
                <Form.Control
                  type="text"
                  name="remark"
                  value={formValues.remark}
                  className="nc-modal-custom-text"
                  onChange={(e) => handleAddDetailsChange(e)}
                />
              </Col>
            </Row>
          </Fragment>

          <Row className="mt-4 pt-3">
            <span className="edit-label mb-2">
              Particulars<span className="color-red"> *</span>
            </span>
            <div className="edit-purchased-items">
              {particulars.length !== 0 ? (
                <>
                  <Table>
                    <thead>
                      <tr>
                        <th className="color-gray">description</th>
                        <th className="color-gray">amount</th>
                        {/* <th className="color-gray">account type</th> */}
                        <th className="color-gray">actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {particulars.map((item, index) => {
                        return (
                          <tr>
                            <td className="text-left" style={{ width: "50%" }}>
                              <Form.Control
                                type="text"
                                onWheel={(e) => e.target.blur()}
                                name="description"
                                value={item.description}
                                onChange={(e) =>
                                  handleParticularChange(
                                    "description",
                                    e.target.value,
                                    index
                                  )
                                }
                                className=""
                              />
                            </td>
                            <td>
                              <Form.Control
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                name="amount"
                                value={item.amount}
                                onChange={(e) =>
                                  handleParticularChange(
                                    "amount",
                                    e.target.value,
                                    index
                                  )
                                }
                                className="text-end"
                              />
                            </td>
                            {/* <td className="">
                              <AntSelect
                                placeholder="SELECT"
                                className="w-90"
                                value={item.account_title}
                                options={accountTypeOptions}
                                // disabled={!openForm}
                                onChange={(e) =>
                                  handleParticularChange(
                                    "account_title",
                                    e,
                                    index
                                  )
                                }
                              />
                            </td> */}
                            <td className="text-center">
                              <img
                                src={trash}
                                onClick={() => handleDelRow(index)}
                                className="cursor-pointer"
                                alt=""
                              />
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </>
              ) : (
                <div className="entries-not-found">
                  There is no particulars recorded yet.
                </div>
              )}
            </div>
          </Row>

          {/* ADD ITEM BUTTON */}
          <Row className="pt-3 PO-add-item">
            <Button type="button" onClick={() => handleAddNewRow()}>
              Add Item
            </Button>
          </Row>

          {/* SUBTOTAL, FREIGHT COST, DISCOUNT, & GRAND TOTAL */}
          <Fragment>
            <Row className="align-right pt-3">
              <Col className="text-end">
                <Form.Check
                  inline
                  label="VAT Ex"
                  name="vat_type"
                  type="radio"
                  value="vat_ex"
                  checked={formValues.vat_type === "vat_ex"}
                  onClick={(e) => {
                    // handleAddDetailsChange(e);
                    if (e.target.checked) {
                      setFormValues((prev) => ({
                        ...prev,
                        vat_type: "vat_ex",
                      }))
                    }
                  }}
                />
                <Form.Check
                  inline
                  label="VAT In"
                  name="vat_type"
                  type="radio"
                  value="vat_in"
                  checked={formValues.vat_type === "vat_in"}
                  onClick={(e) => {
                    // handleAddDetailsChange(e);
                    if (e.target.checked) {
                      setFormValues((prev) => ({
                        ...prev,
                        vat_type: "vat_in",
                      }))
                    }
                  }}
                />
                <Form.Check
                  inline
                  label="ZERO RATED"
                  name="zero_rated"
                  type="radio"
                  value="zero_rated"
                  checked={formValues.vat_type === "zero_rated"}
                  onClick={(e) => {
                    // handleAddDetailsChange(e);
                    if (e.target.checked) {
                      setFormValues((prev) => ({
                        ...prev,
                        vat_type: "zero_rated",
                      }))
                    }
                  }}
                />
              </Col>
            </Row>
            {/* <Row className="align-right pt-3">
              <Col xs={2} className="text-end">
                <span className="edit-label color-gray">Subtotal</span>
              </Col>
              <Col xs={1} className="text-end">
                <span className="edit-label align-middle">PHP</span>
              </Col>
              <Col xs={3} className="text-end">
                <span className="edit-label align-middle">
                  {formatAmount(formValues.subtotal)}
                </span>
              </Col>
            </Row> */}

            {/* {formValues.vat_type === "vat_in" && (
              <>
                <Row className="align-right pt-3">
                  <Col xs={2} className="text-end">
                    <span className="edit-label color-gray">12% VAT</span>
                  </Col>
                  <Col xs={1} className="text-end">
                    <span className="edit-label vat-total-text align-middle">
                      PHP
                    </span>
                  </Col>
                  <Col xs={3} className="text-end">
                    <span className="edit-label align-middle vat-total-text text-end">
                      {formValues.vat_twelve
                        ? formatAmount(
                            parseFloat(formValues.vat_twelve).toFixed(2)
                          )
                        : 0}
                    </span>
                  </Col>
                </Row>
                <Row className="align-right pt-3">
                  <Col xs={2} className="text-end">
                    <span className="edit-label color-gray">Net of VAT</span>
                  </Col>
                  <Col xs={1} className="text-end">
                    <span className="edit-label vat-total-text align-middle">
                      PHP
                    </span>
                  </Col>
                  <Col xs={3} className="text-end">
                    <span className="edit-label align-middle vat-total-text text-end">
                      {formValues.vat_net
                        ? formatAmount(
                            parseFloat(formValues.vat_net).toFixed(2)
                          )
                        : 0}
                    </span>
                  </Col>
                </Row>
                <Row className="align-right pt-3">
                  <Col xs={2} className="text-end">
                    <span className="edit-label color-gray">WHT %</span>
                  </Col>
                  <Col xs={1} className="text-end"></Col>
                  <Col
                    xs={3}
                    className="print-table-footer-label grand-label d-flex justify-content-end"
                  >
                    <Select
                      placeholder="SELECT %"
                      className="w-90"
                      value={formValues.wht_percent}
                      options={[
                        { value: "0", label: "0" },
                        { value: "1", label: "1" },
                        { value: "2", label: "2" },
                        { value: "5", label: "5" },
                        { value: "10", label: "10" },
                        { value: "15", label: "15" },
                      ]}
                      onChange={(e) =>
                        setFormValues((prev) => ({ ...prev, wht_percent: e }))
                      }
                    />
                  </Col>
                </Row>
                <Row className="align-right pt-3">
                  <Col xs={2} className="text-end">
                    <span className="edit-label color-gray">WHT AMOUNT</span>
                  </Col>
                  <Col xs={1} className="text-end">
                    <span className="edit-label vat-total-text align-middle">
                      PHP
                    </span>
                  </Col>
                  <Col xs={3} className="text-end">
                    <span className="edit-label align-middle vat-total-text text-end">
                      {formatAmount(formValues.wht_amount)}
                    </span>
                  </Col>
                </Row>
              </>
            )} */}

            {formValues.vat_type === "vat_in" && (
              <>
                <Row className="align-right pt-3">
                  <Col xs={4} className="text-end">
                    <span className="edit-label color-gray">
                      Total Sales (VAT Inclusive)
                    </span>
                  </Col>
                  <Col xs={1} className="text-end">
                    <span className="edit-label align-middle">PHP</span>
                  </Col>
                  <Col xs={3} className="text-end">
                    <span className="edit-label align-middle">
                      {formatAmount(formValues.vat_in)}
                    </span>
                  </Col>
                </Row>
                <Row className="align-right pt-3">
                  <Col xs={2} className="text-end">
                    <span className="edit-label color-gray">Less: VAT</span>
                  </Col>
                  <Col xs={1} className="text-end">
                    <span className="edit-label vat-total-text align-middle">
                      PHP
                    </span>
                  </Col>
                  <Col xs={3} className="text-end">
                    <span className="edit-label align-middle vat-total-text text-end">
                      {formValues.vat_twelve
                        ? formatAmount(
                            parseFloat(formValues.vat_twelve).toFixed(2)
                          )
                        : 0}
                    </span>
                  </Col>
                </Row>

                <Row className="align-right pt-3">
                  <Col xs={2} className="text-end">
                    <span className="edit-label color-gray">
                      Amount: Net of VAT
                    </span>
                  </Col>
                  <Col xs={1} className="text-end">
                    <span className="edit-label vat-total-text align-middle">
                      PHP
                    </span>
                  </Col>
                  <Col xs={3} className="text-end">
                    <span className="edit-label align-middle vat-total-text text-end">
                      {formValues.vat_net
                        ? formatAmount(
                            parseFloat(formValues.vat_net).toFixed(2)
                          )
                        : 0}
                    </span>
                  </Col>
                </Row>
                <Row className="align-right pt-3">
                  <Col xs={2} className="text-end">
                    <span className="edit-label color-gray">WHT %</span>
                  </Col>
                  <Col xs={1} className="text-end"></Col>
                  <Col
                    xs={3}
                    className="print-table-footer-label grand-label d-flex justify-content-end"
                  >
                    <Select
                      placeholder="SELECT %"
                      className="w-90"
                      allowClear
                      value={whtOptions.find((option) =>
                        option.value.includes(formValues.wht_percent_value)
                      )}
                      // options={whtOptions}
                      options={[
                        { value: "1", label: "1" },
                        { value: "2", label: "2" },
                        { value: "5", label: "5" },
                        { value: "10", label: "10" },
                        { value: "15", label: "15" },
                      ]}
                      onChange={(e) => {
                        // const wht = e.split("_")[1]
                        // const whtValue = e.split("_")[0]
                        setFormValues((prev) => ({
                          ...prev,
                          wht_percent: e,
                          // wht_percent_value: e,
                          // wht_percent_code: whtValue,
                        }))
                      }}
                    />
                  </Col>
                </Row>

                <Row className="align-right pt-3">
                  <Col xs={4} className="text-end">
                    <span className="edit-label color-gray">
                      Less: Withholding Tax
                    </span>
                  </Col>
                  <Col xs={1} className="text-end">
                    <span className="edit-label vat-total-text align-middle">
                      PHP
                    </span>
                  </Col>
                  <Col xs={3} className="text-end">
                    <span className="edit-label align-middle vat-total-text text-end">
                      {formatAmount(formValues.wht_amount)}
                    </span>
                  </Col>
                </Row>
                <Row className="align-right pt-3">
                  <Col xs={2} className="text-end">
                    <span className="edit-label color-gray">Amount Due</span>
                  </Col>
                  <Col xs={1} className="text-end">
                    <span className="edit-label align-middle">PHP</span>
                  </Col>
                  <Col xs={3} className="text-end">
                    <span className="edit-label align-middle">
                      {formatAmount(formValues.amount_due)}
                    </span>
                  </Col>
                </Row>
                <Row className="align-right pt-3">
                  <Col xs={2} className="text-end">
                    <span className="edit-label color-gray">Add: VAT</span>
                  </Col>
                  <Col xs={1} className="text-end">
                    <span className="edit-label align-middle">PHP</span>
                  </Col>
                  <Col xs={3} className="text-end">
                    <span className="edit-label align-middle">
                      {formatAmount(formValues.vat_twelve)}
                    </span>
                  </Col>
                </Row>
              </>
            )}

            <Row className="align-right py-3">
              <Col xs={2} className="text-end">
                <span className="edit-label color-gray grand-total-text">
                  Total Amount Due
                </span>
              </Col>
              <Col xs={1} className="text-end">
                <span className="edit-label align-middle grand-total-text">
                  PHP
                </span>
              </Col>
              <Col xs={3} className="text-end">
                <span className="edit-label align-middle grand-total-text text-end">
                  {formatAmount(formValues.grand_total)}
                </span>
              </Col>
            </Row>
          </Fragment>

          {/* FOOTER: CANCEL & SUBMIT BUTTONS */}
          <div className="d-flex justify-content-end pt-5 pb-3">
            <button
              type="button"
              className="button-secondary me-3"
              onClick={() => navigate(-1)}
            >
              Cancel
            </button>
            {isClicked ? (
              <div className="button-primary d-flex justify-content-center">
                <ReactLoading
                  type="bubbles"
                  color="#FFFFFF"
                  height={50}
                  width={50}
                />
              </div>
            ) : (
              <button
                type="button"
                className="button-primary"
                onClick={handleSubmit}
                disabled={!submittableForm}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

FormBillingSalesInvoice.defaultProps = {
  add: false,
  edit: false,
}

export default FormBillingSalesInvoice
