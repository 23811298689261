import React, { useContext, useEffect, useState } from "react"
import Navbar from "../../Components/Navbar/Navbar"
import { Button, Col, Form, Row, Tab, Tabs } from "react-bootstrap"
import Table from "../../Components/TableTemplate/DataTable"
import AddModal from "../../Components/Modals/AddModal"
import { Modal as AntModal } from "antd"
import ViewModal from "../../Components/Modals/ViewModal"
import DeleteModal from "../../Components/Modals/DeleteModal"
import trash from "../../Assets/Images/trash.png"
import { Table as ReactTable } from "react-bootstrap"

//API CALL HELPERS
import { UsePostRequest } from "../../Helpers/hooks/UsePostApiCall"
import { UseGetRequest } from "../../Helpers/hooks/GetRequests"
import {
  dateFormat,
  dateWithTimeFormat,
  generateId,
  numberFormatWithoutDecimal,
  refreshPage,
  showWarningToast,
  toastStyle,
} from "../../Helpers/Utils/Common"
import toast from "react-hot-toast"
import { Context } from "../../Helpers/Context/Context"
import { Select } from "antd"

const StockOut = () => {
  const [inactive, setInactive] = useState(true)
  const [isClicked, setIsClicked] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [showModal, setShowModal] = useState("")
  const [submittableForm, setSubmittableForm] = useState(false)
  const [itemOptions, setItemOptions] = useState([])
  const [selectedItems, setSelectedItems] = useState([])
  const filteredOptions = itemOptions.filter(
    (option) => !selectedItems.includes(option.value) // Assuming option.value is the identifier
  )
  const [filterConfig, setFilterConfig] = useState({
    tab: "pending",
  })
  const [details, setDetails] = useState({
    name: "",
  })
  const [items, setItems] = useState([
    {
      id: generateId(),
      item_id: "",
      quantity: 0,
      item_remarks: "",
    },
  ])
  const { warehouseId, selectedBuId } = useContext(Context)
  const [tableData, setTableData] = useState([])
  const [showLoader, setShowLoader] = useState(false)

  function handleChange(e, fieldType) {
    const { name, value } = e.target || {}
    let updatedDetails = {}

    if (fieldType === "input") {
      updatedDetails = { ...details, [name]: value }
    } else if (fieldType === "dropdown") {
      updatedDetails = { ...details, [e.for]: e.value }
    }
    setDetails(updatedDetails)
  }

  // function validate(formDetails) {
  //   const validationRules = {
  //     add: () => {
  //       const { item_id, quantity, requisitioner, business_unit_id } =
  //         formDetails
  //       return item_id && quantity > 0 && requisitioner && business_unit_id
  //     },
  //     return: () => {
  //       const { return_quantity } = formDetails
  //       return (
  //         return_quantity > 0 && return_quantity <= parseFloat(details.quantity)
  //       )
  //     },
  //   }

  //   const isFormValid = validationRules[showModal]?.() || false
  //   setSubmittableForm(isFormValid)
  // }

  function AddItem() {
    const newItem = {
      id: generateId(),
      item_id: "",
      quantity: 0,
      item_remarks: "",
    }
    setItems((prevItems) => [...prevItems, newItem])
  }

  function handleRemoveItem(index) {
    setItems((prevItems) => {
      const updatedItems = [...prevItems]
      const itemToRemove = updatedItems[index].item_id // Get the item_id of the item being removed

      // Remove the item
      updatedItems.splice(index, 1)
      setItems(updatedItems)

      // Remove item_id from selectedItems
      if (itemToRemove) {
        setSelectedItems((prevSelected) =>
          prevSelected.filter((item) => item !== itemToRemove)
        )
      }

      return updatedItems
    })
  }

  // useEffect(() => {
  //   validate(details)
  // }, [details])

  async function handleCreate() {
    setIsClicked(true)
    const payload = {
      ...details,
      warehouse_id: warehouseId,
      business_unit_id: selectedBuId,
      item_id: items.map((item) => item.item_id),
      quantity: items.map((item) => item.quantity),
      remark_item: items.map((item) => item.item_remarks),
    }
    const response = await UsePostRequest("stock_outs/create", payload, false)

    if (response.error) {
      toast.error(`Something went wrong.`, {
        style: toastStyle(),
      })
    } else {
      toast.success(response?.data?.response, {
        style: toastStyle(),
      })
      setTimeout(() => {
        refreshPage()
      }, 1000)
    }
    setIsClicked(false)
  }

  async function handleDelete() {
    setIsClicked(true)
    const response = await UsePostRequest(
      "items/delete",
      {
        item_id: details.id,
      },
      false
    )

    //display any errors
    if (response.error) {
      toast.error("Something went wrong", {
        style: toastStyle(),
      })
      return
    }

    if (response) {
      toast.success(response?.data?.response, {
        style: toastStyle(),
      })
      setTimeout(() => {
        refreshPage()
      }, 1000)
    } else {
      setIsClicked(false)
      toast.success(response.error.messages.message, {
        style: toastStyle(),
      })
    }
  }

  const [modal, contextHolder] = AntModal.useModal()

  const confirmApprove = (row) => {
    modal.confirm({
      title: `Are you sure you want to approve this pull out?`,
      // icon: <ExclamationCircleOutlined />,
      // content: "You cannot undo this action.",
      okText: "Approve",
      cancelText: "Cancel",
      onOk: () => handleApprove(row.id),
      okType: "primary",
    })
  }

  const confirmDisapprove = (row) => {
    modal.confirm({
      title: `Are you sure you want to disapprove this pull out?`,
      // icon: <ExclamationCircleOutlined />,
      // content: "You cannot undo this action.",
      okText: "Disapprove",
      cancelText: "Cancel",
      onOk: () => handleDisApprove(row.id),
      okType: "primary",
    })
  }

  async function handleApprove(id) {
    const response = await UsePostRequest("stock_outs/change_status", {
      stock_out_id: id,
      new_status: "approved",
    })
    if (response.response?.request?.status === 200) {
      toast.success(response.data?.response, {
        style: toastStyle(),
      })

      setTimeout(() => {
        refreshPage()
      }, 2000)
    } else {
      toast.error("Something went wrong", {
        style: toastStyle(),
      })
    }
  }

  async function handleDisApprove(id) {
    const response = await UsePostRequest("stock_outs/change_status", {
      stock_out_id: id,
      new_status: "disapproved",
    })
    if (response.response?.request?.status === 200) {
      toast.success(response.data?.response, {
        style: toastStyle(),
      })

      setTimeout(() => {
        refreshPage()
      }, 2000)
    } else {
      toast.error("Something went wrong", {
        style: toastStyle(),
      })
    }
  }

  function ActionBtn(row, status) {
    return (
      <div className="d-flex flex-row gap-2 py-2">
        <button
          className="primary-button-style"
          onClick={() => {
            confirmApprove(row)
          }}
        >
          Approve
        </button>
        <button
          className="danger-button-style"
          onClick={() => {
            confirmDisapprove(row)
          }}
        >
          Disapprove
        </button>
      </div>
    )
  }

  async function handleReturn() {
    setIsClicked(true)
    const response = await UsePostRequest(
      "stock_outs/return",
      { stock_out_id: details.id, return_quantity: details.return_quantity },
      false
    )

    if (response.error) {
      toast.error(`Something went wrong.`, {
        style: toastStyle(),
      })
    } else {
      toast.success(response?.data?.response, {
        style: toastStyle(),
      })
      setTimeout(() => {
        refreshPage()
      }, 1000)
    }
    setIsClicked(false)
  }

  async function fetchTableData() {
    setShowLoader(true)
    const response = await UseGetRequest("stock_outs/get_all_stock_out", {
      status: filterConfig.tab,
      warehouse_id: warehouseId,
      business_unit_id: selectedBuId,
    })
    if (response.data) {
      const data = response.data.data.map((item) => {
        const info = item
        let approved_by = ""
        approved_by = `${item.requisitioner || ""}\n${
          dateWithTimeFormat(item.approved_on) || ""
        }`

        let disapproved_by = `${item.disapproved_by_name || ""}\n${
          dateWithTimeFormat(item.disapproved_on) || ""
        }`

        let requisitioner = `${item.requisitioner || ""}\n${
          dateWithTimeFormat(item.added_on) || ""
        }`

        info.added_on = dateFormat(item.added_on)
        info.approved_by_label = approved_by
        info.disapproved_by_label = disapproved_by
        info.requisitioner = requisitioner
        info.quantity = numberFormatWithoutDecimal(item.quantity)
        return info
      })
      setTableData(data)
    } else {
      setTableData([])
    }
    setShowLoader(false)
  }

  async function fetchItems() {
    const response = await UseGetRequest("items/get_all_item", {
      warehouse_id: warehouseId,
      business_unit_id: selectedBuId,
    })
    if (response.data) {
      const items = response.data.data.map((item) => ({
        ...item,
        for: "item_id",
        label: item.name,
        value: item.id,
      }))

      setItemOptions(items)
    } else {
      setItemOptions([])
    }
  }

  useEffect(() => {
    fetchItems()
    fetchTableData()
  }, [filterConfig])

  useEffect(() => {
    // Function to check if a value is empty, undefined, or null
    const isEmpty = (value) =>
      value === undefined || value === null || value === ""

    // Validate items array
    const areItemsValid = items.every(
      (item) =>
        !isEmpty(item.item_id) && !isEmpty(item.quantity) && item.quantity > 0
    )

    // Validate returnee in details object
    const isDetailsValid = !isEmpty(details.requisitioner)

    setSubmittableForm(areItemsValid && isDetailsValid)
  }, [items, details])

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => setInactive(inactive)}
          active={"PULL OUT"}
        />
      </div>
      <div
        className={`manager-container pt-4 ${inactive ? "inactive" : "active"}`}
      >
        <Row className="mb-1 d-flex flex-row gap-2 gap-lg-0">
          <Col xs={12} lg={7}>
            <h1 className="page-title m-0 w-full">PULL OUT</h1>
          </Col>
          <Col xs={12} lg={3}>
            <input
              type="search"
              name="name"
              placeholder="Search"
              className="search-bar m-0 w-full"
              onChange={(e) => {}}
              onKeyDown={(e) => {
                // if (e.key === "Enter") {
                //   search(e.target.value)
                // }
              }}
            />
          </Col>
          <Col xs={12} lg={2} className="d-flex justify-content-end">
            <button
              className="button-primary"
              onClick={() => {
                setShowModal("add")
              }}
            >
              Add
            </button>
          </Col>
        </Row>
        <Tabs
          activeKey={filterConfig.status}
          defaultActiveKey={filterConfig.status}
          id="SE-tabs"
          onSelect={(e) => {
            setFilterConfig((prev) => ({
              ...prev,
              tab: e,
            }))
          }}
          className="TabStyle1 mt-4"
        >
          <Tab eventKey="pending" title="Pending">
            <div className="pt-3">
              <Table
                tableHeaders={[
                  "ITEM CODE",
                  "ITEM NAME",
                  "QUANTITY",
                  "REQUISITIONER",
                  "REMARKS",
                  "ACTIONS",
                ]}
                headerSelector={[
                  "item_code",
                  "item_name",
                  "quantity",
                  "requisitioner",
                  "remark_item",
                ]}
                tableData={tableData}
                showLoader={showLoader}
                ActionBtn={(row) => ActionBtn(row)}
                handleRowClick={(row) => {
                  setDetails(row)
                  setShowModal("view")
                }}
              />
            </div>
          </Tab>
          <Tab eventKey="approved" title="APPROVED">
            <div className="pt-3">
              <Table
                tableHeaders={[
                  "ITEM CODE",
                  "ITEM NAME",
                  "QUANTITY",
                  "REQUISITIONER",
                  "APPROVED BY",
                  "REMARKS",
                ]}
                headerSelector={[
                  "item_code",
                  "item_name",
                  "quantity",
                  "requisitioner",
                  "approved_by_label",
                  "remark_item",
                ]}
                tableData={tableData}
                showLoader={showLoader}
                handleRowClick={(row) => {
                  setDetails(row)
                  setShowModal("view")
                }}
              />
            </div>
          </Tab>
          <Tab eventKey="disapproved" title="DISAPPROVED">
            <div className="pt-3">
              <Table
                tableHeaders={[
                  "ITEM CODE",
                  "ITEM NAME",
                  "QUANTITY",
                  "REQUISITIONER",
                  "DISAPPROVED BY",
                  "REMARKS",
                ]}
                headerSelector={[
                  "item_code",
                  "item_name",
                  "quantity",
                  "requisitioner",
                  "disapproved_by_label",
                  "item_remarks",
                ]}
                tableData={tableData}
                showLoader={showLoader}
                handleRowClick={(row) => {
                  setDetails(row)
                  setShowModal("view")
                }}
              />
            </div>
          </Tab>
        </Tabs>
      </div>
      <AddModal
        title="PULL OUT"
        size="xl"
        type="branch"
        show={showModal === "add"}
        onHide={() => {
          refreshPage()
          setShowModal("")
        }}
        onSave={handleCreate}
        isClicked={isClicked}
        disableSave={!submittableForm}
      >
        <div className="d-flex justify-content-center align-items-center flex-column">
          <Row className="nc-modal-custom-row d-flex flex-column flex-md-row gap-3 gap-md-0">
            <Col>
              REQUISITIONER<span className="required-icon">*</span>
              <Form.Control
                name="requisitioner"
                onChange={(e) => handleChange(e, "input")}
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row d-flex flex-column flex-md-row gap-3 gap-md-0 px-2 stock-in">
            ITEMS TO PULL OUT
            <div className="purchased-items-table-container">
              <ReactTable>
                <thead>
                  <tr>
                    <th>ITEM</th>
                    <th>ITEM CODE</th>
                    <th>CURRENT</th>
                    <th>QUANTITY</th>
                    <th>REMARKS</th>
                    <th>ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {items !== undefined &&
                    items?.map((item, index) => {
                      return (
                        <tr key={item.id}>
                          <td>
                            <Select
                              name="item_id"
                              className="w-full"
                              style={{ width: 200 }}
                              classNamePrefix="react-select"
                              placeholder="Select item"
                              onChange={(e) => {
                                // Remove the previously selected item from selectedItems
                                const previousItemId = items[index].item_id // Store previous item_id for reinsertion
                                if (previousItemId) {
                                  setItemOptions((prevOptions) => {
                                    // Check if previousItem is already in itemOptions
                                    const optionToReinsert = itemOptions.find(
                                      (option) =>
                                        option.value === previousItemId
                                    )
                                    if (
                                      optionToReinsert &&
                                      !prevOptions.some(
                                        (option) =>
                                          option.value === previousItemId
                                      )
                                    ) {
                                      return [...prevOptions, optionToReinsert]
                                    }
                                    return prevOptions
                                  })
                                }

                                // Update selectedItems with the new selection
                                setSelectedItems((prev) => [
                                  ...prev.filter(
                                    (item) => item !== previousItemId
                                  ),
                                  e,
                                ])

                                //get the current inventory
                                const selectedOption = filteredOptions.find(
                                  (option) => option.value === e
                                )

                                // Update items with the new selected option
                                const updatedItems = [...items]
                                updatedItems[index] = {
                                  ...updatedItems[index],
                                  item_id: e,
                                  current_inventory:
                                    selectedOption.current_inventory,
                                  item_code: selectedOption.item_code,
                                }
                                setItems(updatedItems)
                              }}
                              options={filteredOptions}
                              getPopupContainer={(trigger) =>
                                trigger.parentNode
                              }
                            />
                          </td>
                          <td>
                            <Form.Control
                              disabled
                              value={item.item_code}
                              className="nc-modal-custom-input-edit"
                            />
                          </td>
                          <td>
                            <Form.Control
                              disabled
                              value={item.current_inventory}
                              className="nc-modal-custom-input-edit"
                            />
                          </td>
                          <td>
                            <Form.Control
                              name="quantity"
                              type="number"
                              onKeyDown={(e) => {
                                if (
                                  e.key === "ArrowUp" ||
                                  e.key === "ArrowDown"
                                ) {
                                  e.preventDefault()
                                }
                              }}
                              onWheel={(e) => e.target.blur()}
                              onChange={(e) => {
                                if (
                                  parseFloat(e.target.value) >
                                  parseFloat(item.current_inventory)
                                ) {
                                  showWarningToast(
                                    `Pull out qty should not exceed the current inventory qty`
                                  )
                                  setSubmittableForm(false)
                                } else {
                                  const updatedItems = [...items]
                                  updatedItems[index].quantity = e.target.value
                                  setItems(updatedItems)
                                }
                              }}
                              className="nc-modal-custom-input-edit"
                            />
                          </td>
                          <td>
                            <Form.Control
                              name="remarks"
                              type="text"
                              onChange={(e) => {
                                const updatedItems = [...items]
                                updatedItems[index].item_remarks =
                                  e.target.value
                                setItems(updatedItems)
                              }}
                              className="nc-modal-custom-input-edit"
                            />
                          </td>
                          <td style={{ width: "5%" }}>
                            <img
                              src={trash}
                              onClick={() => handleRemoveItem(index)}
                              className="cursor-pointer p-0 m-0"
                              alt=""
                            />
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </ReactTable>
            </div>
          </Row>
          <Row className="pt-3 PO-add-item d-flex w-full justify-content-start flex-row">
            <Button type="button" onClick={() => AddItem()}>
              Add Item
            </Button>
          </Row>
        </div>
      </AddModal>
      <ViewModal
        withButtons
        size="lg"
        withHeader={true}
        title="PULL OUT"
        editable={false}
        can_delete={false}
        // can_return={filterConfig.tab === "approved"}
        isClicked={isClicked}
        onReturn={() => {
          setShowModal("return")
        }}
        show={showModal === "view"}
        onHide={() => setShowModal("")}
        onEdit={() => {
          setShowModal("edit")
        }}
        onDelete={() => setShowModal("delete")}
      >
        <div className="d-flex justify-content-center align-items-center flex-column">
          <Row className="nc-modal-custom-row d-flex flex-column flex-md-row gap-3 gap-md-0">
            <Col>
              NAME<span className="required-icon">*</span>
              <Form.Control
                name="name"
                type="text"
                disabled
                value={details.item_name}
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col>
              QUANTITY<span className="required-icon">*</span>
              <Form.Control
                name="quantity"
                disabled
                value={details.quantity}
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col>
              REMARKS
              <Form.Control
                name="remarks"
                type="text"
                disabled
                value={details.remark_item}
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
        </div>
      </ViewModal>
      <AddModal
        title="RETURN"
        size="lg"
        type="branch"
        show={showModal === "return"}
        onHide={() => setShowModal("view")}
        onSave={handleReturn}
        isClicked={isClicked}
        disableSave={!isValid}
      >
        <div className="d-flex justify-content-center align-items-center flex-column">
          <Row className="nc-modal-custom-row d-flex flex-column flex-md-row gap-3 gap-md-0">
            <Col>
              BORROWED QUANTITY
              <Form.Control
                type="number"
                disabled
                value={
                  filterConfig.tab === "processed"
                    ? details.quantity
                    : details.return_balance
                }
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col>
              RETURN QUANTITY<span className="required-icon">*</span>
              <Form.Control
                name="return_quantity"
                type="number"
                onWheel={(e) => e.target.blur()}
                onChange={(e) => {
                  // handleChange(e, "input")
                  if (
                    e.target.value <= 0 ||
                    details.quantity < e.target.value
                  ) {
                    setIsValid(false)
                  } else {
                    setIsValid(true)
                    setDetails((prev) => ({
                      ...prev,
                      return_quantity: e.target.value,
                    }))
                  }
                }}
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
        </div>
      </AddModal>
      <DeleteModal
        text="item"
        show={showModal === "delete"}
        onHide={() => setShowModal("edit")}
        onDelete={handleDelete}
      />
      {contextHolder}
    </div>
  )
}

export default StockOut
