import React, { useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { Select as AntSelect, Select, Upload } from "antd"
//components
import Table from "../../Components/TableTemplate/DataTable"
import Navbar from "../../Components/Navbar/Navbar"
import DeleteModal from "../../Components/Modals/DeleteModal"
import AddModal from "../../Components/Modals/AddModal"
import EditModal from "../../Components/Modals/EditModal"
import ViewModal from "../../Components/Modals/ViewModal"
import toast from "react-hot-toast"
import { refreshPage, toastStyle } from "../../Helpers/Utils/Common"
import { UseGetRequest } from "../../Helpers/hooks/GetRequests"
import { UsePostRequest } from "../../Helpers/hooks/UsePostApiCall"
import { getDistributor } from "../../Helpers/apiCalls/DistributorApi"
import Compressor from "compressorjs"

export default function WarehouseManager() {
  const [inactive, setInactive] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [isClicked, setIsClicked] = useState(false)
  const [businessUnits, setBusinessUnits] = useState(false)
  const [filePreview, setFilePreview] = useState(null)

  const handleUploadChange = async (info) => {
    const file = info.file.originFileObj
    setFilePreview(URL.createObjectURL(file))
    const compressedImage = await new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.6,
        success(result) {
          resolve(result)
        },
        error(err) {
          reject(err)
        },
      })
    })
    setFormValues((prevDetails) => ({
      ...prevDetails,
      file: compressedImage,
    }))
  }

  const props = {
    name: "file",
    headers: {
      authorization: "authorization-text",
    },
    onChange: handleUploadChange,
    showUploadList: false,
    accept: "image/*",
  }

  // DELETE
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const handleCloseDeleteModal = () => setShowDeleteModal(false)

  // VIEW
  const [showViewCustomerModal, setShowViewCustomerModal] = useState(false)

  // EDIT
  const [showEditCustomerModal, setShowEditCustomerModal] = useState(false)
  const handleCloseEditProjectModal = () => {
    setShowEditCustomerModal(false)
    resetValues()
  }

  const [showAddCustomerModal, setShowAddCustomerModal] = useState(false)

  //API
  const [tableData, setTableData] = useState([
    { name: "Sample Warehouse", business_units: [{ name: "SAMPLE" }] },
  ])
  const [selectedRow, setSelectedRow] = useState([])
  const [formValues, setFormValues] = useState({
    name: "",
    business_unit_ids: [],
  })

  const [requiredFieldKeys, setRequiredFieldKeys] = useState([
    "name",
    "business_unit_ids",
  ])

  function resetValues() {
    setFormValues({
      name: "",
    })
  }

  const handleAddChange = (e) => {
    const { name, value } = e.target
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleEditChange = (e) => {
    const { name, value } = e.target
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  async function create() {
    setIsClicked(true)
    const formDataValues = Object.entries(formValues).map(([key, value]) => ({
      key,
      value,
    }))

    const response = await UsePostRequest(
      "warehouses/create",
      formDataValues,
      true
    )
    if (response.data?.status === "success") {
      toast.success(response.data?.response, {
        style: toastStyle(),
      })
      setTimeout(() => {
        refreshPage()
      }, [3000])
    } else if (response.error?.response?.status === 400) {
      toast.error(response.error.response?.data?.messages?.response, {
        style: toastStyle(),
      })
    }
    setIsClicked(false)
  }

  async function fetchWarehouses() {
    setTableData([])
    setShowLoader(true)
    const response = await UseGetRequest("warehouses/get_all_warehouse")
    if (response.data) {
      const formattedData = response.data.data.map((warehouse) => ({
        ...warehouse,
      }))
      setTableData(formattedData)
    }
    setShowLoader(false)
  }

  async function fetchBusinessUnits() {
    try {
      const response = await getDistributor({ name: "" })
      if (response.data && Array.isArray(response.data.data)) {
        let data = response.data.data.map((bu) => {
          return {
            label: bu.name,
            value: bu.id,
          }
        })
        setBusinessUnits(data)
      } else {
        setBusinessUnits([])
      }
    } catch (error) {
      setBusinessUnits([])
    }
  }

  async function searchWarehouse(text) {
    setTableData([])
    setShowLoader(true)
    const response = await UseGetRequest("warehouses/search", { name: text })
    if (response.data) {
      const formattedData = response.data.data.map((warehouse) => ({
        ...warehouse,
      }))
      setTableData(formattedData)
    }
    setShowLoader(false)
  }

  async function del() {
    setIsClicked(true)
    const response = await UsePostRequest("warehouses/delete", {
      warehouse_id: selectedRow.id,
    })
    if (response) {
      toast.success("Warehouse deleted successfully", {
        style: toastStyle(),
      })
      handleCloseDeleteModal()
      setShowViewCustomerModal(false)
      setTimeout(() => {
        refreshPage()
      }, 2000)
    } else {
      toast.error(response.error?.data.messages.error, {
        style: toastStyle(),
      })
    }
  }

  async function editWarehouse() {
    setIsClicked(true)
    const businessUnitIds = Array.isArray(formValues.business_unit_ids)
      ? formValues.business_unit_ids.map((item) => {
          return typeof item === "object" && item.value ? item.value : item
        })
      : formValues.business_unit_ids
    const payload = {
      name: formValues.name,
      warehouse_id: formValues.id,
      business_unit_ids: businessUnitIds,
      file: formValues.file ? formValues.file : "",
    }

    const formDataValues = Object.entries(payload).map(([key, value]) => ({
      key,
      value,
    }))

    const response = await UsePostRequest(
      "warehouses/update",
      formDataValues,
      true
    )
    if (response) {
      if (response) {
        toast.success("Warehouse details updates successfully", {
          style: toastStyle(),
        })
        setShowEditCustomerModal(false)
        setShowViewCustomerModal(false)
        resetValues()
        fetchWarehouses()
      } else {
        toast.error(response.error.data.messages.error, {
          style: toastStyle(),
        })
      }
    }
    setIsClicked(false)
  }

  React.useEffect(() => {
    fetchWarehouses()
    fetchBusinessUnits()
  }, [])

  const [submittableForm, setSubmittableForm] = useState(false)
  React.useEffect(() => {
    let isValid = true
    requiredFieldKeys.forEach((key) => {
      if (
        !formValues[key] ||
        (formValues[key] &&
          typeof formValues[key] === "string" &&
          formValues[key]?.trim() === "")
      ) {
        isValid = false
      }

      if (
        formValues.business_unit_ids &&
        formValues.business_unit_ids.length === 0
      ) {
        isValid = false
      }
    })
    setSubmittableForm(isValid)
  }, [formValues.name, formValues.business_unit_ids])

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive)
          }}
          active={"WAREHOUSES"}
        />
      </div>
      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        <Row className="mb-4">
          <Col xs={6}>
            <h1 className="page-title"> WAREHOUSES </h1>
          </Col>
          <Col className="d-flex justify-content-end">
            <input
              type="search"
              name="name"
              placeholder="Search name"
              className="search-bar"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  searchWarehouse(e.target.value)
                }
              }}
            />
            <button
              className="add-btn"
              onClick={() => setShowAddCustomerModal(true)}
            >
              Add
            </button>
          </Col>
        </Row>

        <div className="tab-content">
          <Table
            tableHeaders={["NAME", "BUSINESS UNITS"]}
            headerSelector={["name", "business_units"]}
            tableData={tableData}
            showLoader={showLoader}
            handleRowClick={(row) => {
              const editcustomer = {
                ...row,
                business_unit_ids: row.business_units?.map((item) => {
                  return {
                    label: item.business_unit_name,
                    value: item.business_unit_id,
                  }
                }),
              }
              setSelectedRow(row)
              setFormValues(editcustomer)
              setRequiredFieldKeys(["name", "business_unit_ids"])
              setShowViewCustomerModal(true)
            }}
            isPointer
          />
        </div>
      </div>

      {/* MODALS */}
      <DeleteModal
        text="warehouse"
        show={showDeleteModal}
        onHide={handleCloseDeleteModal}
        onDelete={del}
        isLoading={isClicked}
      />
      <AddModal
        title="WAREHOUSE"
        size="xl"
        type="branch"
        show={showAddCustomerModal}
        onHide={() => {
          setShowAddCustomerModal(false)
          resetValues()
        }}
        onSave={() => create()}
        isClicked={isClicked}
        data={formValues}
        disableSave={!submittableForm}
      >
        <div className="mt-3">
          <Row className="nc-modal-custom-row">
            <Col xs={12}>
              NAME
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="name"
                value={formValues.name}
                className="nc-modal-custom-input"
                onChange={(e) => handleAddChange(e)}
                required
              />
            </Col>

            {formValues.type !== "admin" && (
              <Col xs={12} className="mt-4">
                BUSINESS UNITS
                <span className="required-icon">*</span>
                <Col className="mb-2 mb-lg-0">
                  <Select
                    showSearch
                    mode="multiple"
                    className="react-select-container w-100"
                    classNamePrefix="react-select"
                    placeholder="Select business unit(s)"
                    onChange={(e) => {
                      setFormValues((prev) => ({
                        ...prev,
                        business_unit_ids: e,
                      }))
                    }}
                    options={businessUnits}
                    dropdownStyle={{ zIndex: 10000 }}
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  />
                </Col>
              </Col>
            )}
            <Col>
              {filePreview && (
                <div className="mt-3">
                  <img
                    src={filePreview}
                    alt="preview"
                    className="image-preview-container"
                  />
                </div>
              )}

              <Upload {...props}>
                <button className="button-fourth ms-2 mt-2">Upload Logo</button>
              </Upload>
            </Col>
          </Row>
        </div>
      </AddModal>

      <EditModal
        title="WAREHOUSE"
        size="xl"
        type="project"
        show={showEditCustomerModal}
        onHide={handleCloseEditProjectModal}
        onSave={() => editWarehouse()}
        data={formValues}
        isClicked={isClicked}
        disableSave={!submittableForm}
      >
        <div className="mt-3">
          <Row className="nc-modal-custom-row">
            <Col xs={12}>
              NAME
              <span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="name"
                value={formValues.name}
                className="nc-modal-custom-input"
                onChange={(e) => handleEditChange(e)}
                required
              />
            </Col>

            {formValues.type !== "admin" && (
              <Col xs={12} className="mt-3">
                BUSINESS UNITS
                <span className="required-icon">*</span>
                <Col className="mb-2 mb-lg-0">
                  <Select
                    showSearch
                    mode="multiple"
                    className="react-select-container w-100"
                    classNamePrefix="react-select"
                    placeholder="Select business unit(s)"
                    value={formValues.business_unit_ids}
                    onChange={(e) => {
                      setFormValues((prev) => ({
                        ...prev,
                        business_unit_ids: e,
                      }))
                    }}
                    options={businessUnits}
                    dropdownStyle={{ zIndex: 10000 }}
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  />
                </Col>
              </Col>
            )}
            <Col className="mt-3">
              {filePreview && (
                <div className="mt-3">
                  <img
                    src={filePreview}
                    alt="preview"
                    className="image-preview-container"
                  />
                </div>
              )}

              <Upload {...props}>
                <button className="button-fourth ms-2 mt-2">Upload Logo</button>
              </Upload>
            </Col>
          </Row>
        </div>
      </EditModal>

      <ViewModal
        withButtons
        size="xl"
        show={showViewCustomerModal}
        onHide={() => {
          resetValues()
          setShowViewCustomerModal(false)
        }}
        onEdit={() => {
          setShowEditCustomerModal(true)
          setShowViewCustomerModal(false)
          setFilePreview(formValues.logo && formValues.logo[0]?.base_64)
        }}
        onDelete={() => setShowDeleteModal(true)}
      >
        <div className="mt-1">
          <span className="custom-modal-body-title">WAREHOUSE DETAILS</span>
          <Row className="nc-modal-custom-row">
            <Col xs={12}>
              NAME
              <Form.Control
                type="text"
                name="name"
                value={formValues.name}
                className="nc-modal-custom-input"
                disabled
              />
            </Col>

            {formValues.type !== "admin" && (
              <Col xs={12} className="mt-3">
                BUSINESS UNITS
                <span className="required-icon">*</span>
                {formValues.business_units?.map((item, index) => (
                  <Form.Control
                    key={index}
                    type="text"
                    name="account_no"
                    defaultValue={item.business_unit_name}
                    className="nc-modal-custom-input mb-2"
                    disabled
                  />
                ))}
              </Col>
            )}
            <Col className="mt-3">
              <div className="mt-3">
                <img
                  src={formValues.logo && formValues.logo[0]?.base_64}
                  alt="preview"
                  className="image-preview-container"
                />
              </div>
            </Col>
          </Row>
        </div>
      </ViewModal>
    </div>
  )
}
