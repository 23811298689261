import { getUser, getToken, getAPIKey } from "../Utils/Common"
import axios from "axios"
import toast from "react-hot-toast"

const postConfig = {
  headers: {
    "api-key": "daccfc89-ff47-4ce1-99bf-5ad2d8f57282",
    "user-key": getAPIKey(),
    "Content-Type": "application/json",
  },
}

const postConfigFormData = {
  headers: {
    "api-key": "daccfc89-ff47-4ce1-99bf-5ad2d8f57282",
    "user-key": getAPIKey(),
    "Content-Type": "multipart/form-data",
  },
}

// DON'T BE TRICKED, THIS MAY BE POST BUT THIS ACTUALLY IS A GET INSIDE A POST METHOD.
// BACKEDN tingz I have to conform to
export const UsePostRequest = async (endpoint, values, isFormData = false) => {
  try {
    var payload = isFormData ? new FormData() : {}

    if (isFormData) {
      payload.append("requester", getUser())
      payload.append("token", getToken())

      values.forEach((data) => {
        if (Array.isArray(data.value)) {
          // Check if the array contains arrays
          const hasArrays = data.value.some((item) => Array.isArray(item))
          if (hasArrays) {
            // If the array contains arrays, append each sub-array as a separate parameter
            data.value.forEach((itemArray, index) => {
              itemArray.forEach((item) => {
                payload.append(`${data.key}[${index}][]`, item)
              })
            })
          } else {
            // Modification to address missing payload variables if lengths are 0
            if (data.value && data.value.length > 0) {
              data.value.forEach((item) => {
                payload.append(`${data.key}[]`, item)
              })
            } else {
              payload.append(`${data.key}[]`, [])
            }
          }
        } else {
          payload.append(data.key, data.value)
        }
      })
    } else {
      payload = {
        requester: getUser(),
        token: getToken(),
        ...values,
      }
    }

    const response = await axios.post(
      `${process.env.REACT_APP_LINK}${endpoint}`,
      payload,
      isFormData ? postConfigFormData : postConfig
    )
    return { data: response.data, response: response }
  } catch (error) {
    // return {error: error.response}
    return { error: error }
  }
}
