import { ConfigProvider, theme } from "antd"
import React, { useContext, useMemo } from "react"
import { Context } from "../Context/Context"

const ThemeProvider = ({ children }) => {
  const { primaryColor } = useContext(Context)
  const customTheme = useMemo(
    () => ({
      algorithm: theme.defaultAlgorithm,
      token: {
        colorPrimary: primaryColor || "#9692CA",
        borderRadius: 5,
        wireframe: false,
        fontFamily: "font-primary-regular, sans-serif",
      },
    }),
    [primaryColor]
  )

  return <ConfigProvider theme={customTheme}>{children}</ConfigProvider>
}

export default ThemeProvider
