import React, { useContext, useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import WarehouseLogo from "../../Assets/Images/_warehouse.png"
import { BarLoader } from "react-spinners"
import { useNavigate } from "react-router-dom"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { UseGetRequest } from "../../Helpers/hooks/GetRequests"
import { Context } from "../../Helpers/Context/Context"
import ColorThief from "colorthief"

export default function WarehouseList() {
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const { setWarehouseId, buName } = useContext(Context)
  const {
    selectedBuId,
    setWarehouseName,
    setPrimaryColor,
    setBusinessUnitLogo,
  } = useContext(Context)
  const [warehouses, setWarehouses] = useState([
    {
      id: 1,
      name: "Warehouse 1",
      logo: WarehouseLogo,
    },
  ])

  const rgbToHex = (rgb) => {
    return `#${rgb.map((x) => x.toString(16).padStart(2, "0")).join("")}`
  }

  function changeTheme(image) {
    setBusinessUnitLogo(image)
    const img = new Image()
    img.crossOrigin = "Anonymous"
    img.src = image

    img.onload = () => {
      const colorThief = new ColorThief()
      const dominantColor = colorThief.getColor(img)
      const hexColor = rgbToHex(dominantColor)
      setPrimaryColor(hexColor)
      localStorage.setItem("primaryColor", hexColor)
    }
  }

  async function fetchWarehouses() {
    setWarehouses([])
    setLoading(true)
    const response = await UseGetRequest("warehouses/get_all_warehouse", {
      business_unit_id: selectedBuId,
    })
    if (response.data.status === "success") {
      setWarehouses(response.data.data)
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchWarehouses()
  }, [])

  return (
    <div className="landing-page-container">
      <div className="fixed-top landing-page-header">
        <Row className="d-flex flex-row justify-content-center align-items-center">
          <Col
            className="d-flex flex-row text-white justify-content-start align-items-center pe-4 font-primary cursor gap-2 me-5 px-5 pt-3"
            onClick={() => navigate(`/business-units`)}
          >
            <ArrowBackIcon />
            <p className="font-primary m-0 text-white">BACK</p>
          </Col>
          <Col className="d-flex flex-row text-white justify-content-center align-items-center pe-4 font-primary cursor gap-2 me-5 px-5 pt-3">
            <p className="font-primary m-0 text-white text-center">{buName}</p>
          </Col>
          <Col className="w-full d-flex landing-page-logo ms-2 px-5"></Col>
        </Row>
      </div>
      <div className="bu-units-container">
        {loading ? (
          <Row className="d-flex flex-row justify-content-center align-items-center w-full mt-5 pt-5">
            <BarLoader color="white" />
          </Row>
        ) : (
          <Row className="d-flex flex-wrap px-5 pt-2 card-row overflow-scroll">
            {Array.isArray(warehouses) &&
              warehouses.length !== 0 &&
              warehouses.map((warehouse) => (
                <Col
                  key={warehouse.id}
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  className="p-3 position-relative"
                  onClick={() => {
                    changeTheme(
                      warehouse.logo.length !== 0
                        ? warehouse.logo[0]?.base_64
                        : WarehouseLogo
                    )
                    setWarehouseId(warehouse.id)
                    setWarehouseName(warehouse.name)
                    navigate(`/items-list`)
                  }}
                >
                  <div className="card-container d-flex flex-column justify-content-center align-items-center">
                    <img
                      src={
                        warehouse.logo.length !== 0
                          ? warehouse.logo[0]?.base_64
                          : WarehouseLogo
                      }
                      alt=""
                      className="m-3 card-image"
                    />
                    <h6 className="m-0 px-3 pb-2 text-center font-primary">
                      {warehouse.name}
                    </h6>
                  </div>
                </Col>
              ))}
          </Row>
        )}
      </div>
    </div>
  )
}
