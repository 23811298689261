import React, { createContext, useEffect, useState } from "react"

export const Context = createContext()

export const BuProvider = ({ children }) => {
  const [selectedBuId, setSelectedBuId] = useState(() => {
    return localStorage.getItem("selectedBuId") || ""
  })

  const [primaryColor, setPrimaryColor] = useState(() => {
    return localStorage.getItem("primaryColor") || ""
  })

  const [businessUnitLogo, setBusinessUnitLogo] = useState(() => {
    return localStorage.getItem("buLogo") || ""
  })

  const [buName, setBuName] = useState(() => {
    return localStorage.getItem("buName") || ""
  })

  const [warehouseName, setWarehouseName] = useState(() => {
    return localStorage.getItem("warehouseName") || ""
  })

  const [warehouseId, setWarehouseId] = useState(() => {
    return localStorage.getItem("warehouseId") || ""
  })

  const [option, setOption] = useState(() => {
    return localStorage.getItem("option") || ""
  })

  useEffect(() => {
    if (selectedBuId) {
      localStorage.setItem("selectedBuId", selectedBuId)
    } else {
      localStorage.removeItem("selectedBuId")
    }
  }, [selectedBuId])

  useEffect(() => {
    if (warehouseName) {
      localStorage.setItem("warehouseName", warehouseName)
    } else {
      localStorage.removeItem("warehouseName")
    }
  }, [warehouseName])

  useEffect(() => {
    if (buName) {
      localStorage.setItem("buName", buName)
    } else {
      localStorage.removeItem("buName")
    }
  }, [buName])

  useEffect(() => {
    localStorage.setItem("primaryColor", primaryColor)
  }, [primaryColor])

  useEffect(() => {
    localStorage.setItem("buLogo", businessUnitLogo)
  }, [businessUnitLogo])

  useEffect(() => {
    localStorage.setItem("option", option)
  }, [option])

  useEffect(() => {
    localStorage.setItem("warehouseId", warehouseId)
  }, [warehouseId])

  return (
    <Context.Provider
      value={{
        selectedBuId,
        setSelectedBuId,
        primaryColor,
        setPrimaryColor,
        businessUnitLogo,
        setBusinessUnitLogo,
        buName,
        setBuName,
        option,
        setOption,
        warehouseId,
        setWarehouseId,
        warehouseName,
        setWarehouseName,
      }}
    >
      {children}
    </Context.Provider>
  )
}
